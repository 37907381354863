export const config = {
  mobile: window.innerWidth < 577 ? true : false,

  versao: "01.01.004/002",

  atualizado: "XX/XX/XXXX",

  //urlApi: "http://localhost/organicosmantiqueira_portal/backend/api/",
  //urlApi: "http://192.168.1.121/organicosmantiqueira_portal/backend/api/",
    urlApi: "https://pedidos.organicosdamantiqueira.com.br/backend/api/",
};

export const routes = {
  login: "/login",
  recuperarSenha: "/recuperar-senha",
  root: "/",
  pedido: "/pedido",
  cadastro: "/pre-cadastro",
  redefinirSenha: "/redefinir-senha",
  alterarSenha: "/alterar-senha",
  perfil: "/perfil",
  administrativo: "/administrativo",
  reiniciosenha: "/reinicio-senha",
  administrativoEditar: "/administrativoeditar",
};
