import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { routes } from "./config";
import IdleTimer, { IdleTimerProvider } from 'react-idle-timer';
import { useRef } from "react";
import AutenticarService from "./service/AutenticarService";


const Auth = lazy(() => import("./component/auth"));
const RecuperarSenha = lazy(() => import("./component/recuperar_senha"));
const Admin = lazy(() => import("./Admin"));
const ClientePre = lazy(() => import("./component/cliente/ClientePre"));
const RedefinirSenha = lazy(() => import("./component/redefinir_senha"));
const onIdle = () =>{
   AutenticarService.logout();
}

const Public = () => (
  <Router basename="/">
    <Suspense
      fallback={
        <div className="d-flex justify-content-center mt-5 pt-5">
          {" "}
          <CircularProgress />{" "}
        </div>
      }
    >
      <IdleTimerProvider
      ref={useRef(null)}
      timeout={15 * 60 * 1000}
      onIdle={onIdle}>
        <Switch>
          <Route path={routes.login} component={Auth} />
          <Route path={routes.recuperarSenha} component={RecuperarSenha} />
          <Route path={routes.redefinirSenha} component={RedefinirSenha} />
          <Route path={routes.cadastro} component={ClientePre} />
          <Route path={routes.root} component={Admin} />
          {/* <Route path={routes.root} component={Administrativo} /> */}
        </Switch>
      </IdleTimerProvider>
    </Suspense>
  </Router>
);

export default Public;
