import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Public from './Public'
import { ThemeGreen } from './assets/themes';

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/App.css'

const App = () => (    
    <ThemeProvider theme={ThemeGreen}>
        <Public />
    </ThemeProvider>    
)

export default App;
