import { routes } from '../config';

class AutenticarService {    
    static login(token, codigo, nomeCliente, email, pessoa, documento, superUser) {
        sessionStorage.setItem('x-token', token);
        sessionStorage.setItem('codigo', codigo);
        sessionStorage.setItem('nomeCliente', nomeCliente);                        
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('pessoa', pessoa);
        sessionStorage.setItem('documento', documento);
        sessionStorage.setItem('superUser', superUser);       
    }

    static getToken() {
        let token = sessionStorage.getItem('x-token')

        if (token === '') {
            return undefined
        }

        if (token === null) {
            return undefined
        }

        if (token === 'null') {
            return undefined
        }

        if (token === 'undefined') {
            return undefined
        }

        return token;
    }

    static getCodigo() {
        return sessionStorage.getItem('codigo');
    }

    static getPessoa() {
        return sessionStorage.getItem('pessoa');
    }

    static getNomeCliente() {
        return sessionStorage.getItem('nomeCliente');
    } 
    
    static getEmail() {
        return sessionStorage.getItem('email');
    } 

    static setUserName(nomeCliente) {
        sessionStorage.setItem('nomeCliente', nomeCliente);
    }    

    static getDocumento() {
        return sessionStorage.getItem('documento');
    } 
    static getSuperUser() {
        return sessionStorage.getItem('superUser');
    } 

    static logout() {
        sessionStorage.removeItem('x-token');
        sessionStorage.removeItem('codigo');
        sessionStorage.removeItem('nomeCliente');  
        sessionStorage.removeItem('email');  
        sessionStorage.removeItem('pessoa');  
        sessionStorage.removeItem('documento');  
        
        window.location.href = routes.login;
    }    

    static isAuthorized() {
        let token = AutenticarService.getToken();    
        
        if (token === null) {        
            return false;
        } 
    
        if (token === undefined) {        
            return false;
        } 
    
        if (token === '') {        
            return false;
        }
    
        if (token === 'null') {        
            return false;
        }
    
        if (token === 'undefined') {        
            return false;
        }       
    
        return true;
    }
}

export default AutenticarService
